// ==== flex related ====
//for displaying flex with optional direction
@mixin flex($direction: row) {
    display: flex;
    @if ($direction==col) {
        flex-direction: column;
    }
    @else if ($direction==row-r) {
        flex-direction: row-reverse;
    }
    @else if ($direction==col-r) {
        flex-direction: column-reverse;
    }
    @else {
        flex-direction: $direction;
    }
}

@mixin font-face($name, $weight: normal, $style: normal) {
    @font-face {
        font-family: $name;
        font-style: $style;
        font-weight: $weight;
        @content;
    }
}

@mixin font($name, $size, $unit: rem, $weight: 300) {
    font-family: $name;
    font-size: $size + $unit;
    font-weight: $weight;
}

//for dropdown menu override
@mixin dropdown-menu($placement: l) {
    .dropdown-menu {
        @if ($placement==l) {
            left: 0;
        }
        @else if($placement==r) {
            right: 0;
        }
        @content;
    }
}

//mixin for creating triangle on
@mixin css-triangle($direction, $color, $size: 12px, $position: absolute) {
    @include pseudo($pos: $position);
    @if $direction==down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        top: 100%;
        left: calc(50% - #{$size});
    }
    @else if $direction==up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        top: -#{$size};
        left: calc(50% - #{$size});
    }
    @else if $direction==right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        top: calc(50% - #{$size});
        left: 100%;
    }
    @else if $direction==left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        top: calc(50% - #{$size});
        left: -#{$size};
    }
}

@mixin arrow-down($color, $position: 92% 50%) {
    // background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='#{$color}' height='1024' width='767.5' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 384l383.75 383.75L767.5 384H0z'/%3e%3c/svg%3e");
    // @if $color == color_new(blue, diversey_blue) {
    //     background-image: url("/assets/images/caret_down.png");
    // }
    @if $color == color(aqua, dark) {
        background-image: url("/assets/images/caret_down.png");
    }
    @else if $color == $color_white {
        background-image: url("/assets/images/triangle-down-white.png");
    }
    @else if $color == color(aqua, base) {
        background-image: url("/assets/images/triangle-down-white.png");
    }
    @else {
        background-image: url("/assets/images/caret_down.png");
    }
    background-repeat: no-repeat;
    // background-size: 8px 14px;
    background-size: auto;
    background-position: $position;
}

// Using it for listing pages.
@mixin equal-sized-columns($min,
$max) {
    @for $i from $min through $max {
        &:first-child:nth-last-child(#{$i}),
        &:first-child:nth-last-child(#{$i})~& {
            max-width: calc(1 / $i ) * 100%;
        }
    }
}
