@import 'config';
@import 'mixins';

.pagination-container {
    @include flex(row);
    padding: 0.5em;
    background: $color_white;
    margin-top: 1em;
    justify-content: flex-end;
    align-items: center;
    font-family:$font-family-arial;
    font-size: 14px;
    color: color(grey, darker);
    .pagination {
        padding-left: 1em;
        @include flex(row);
        align-items: center;
    }
    li {
        margin: 0 5px;
        a {
            padding: 0 5px;
            display: block;
        }
    }
    .active {
        position: relative;
        a {
            font-weight: 600;
            &:after {
                content: "";
                border-bottom: 2px solid color(grey, darker);
                position: absolute;
                width: 100%;
                height: 2px;
                left: 0;
                top: 100%;
            }
        }
    }
    .pagination-first,
    .pagination-last {
        a {
            background-color: color(grey, darker);
            border-radius: 100%;
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            i {
                color: $color_white;
            }
        }
    }
    .pagination-info {
        position: relative;
        padding-right: 1em;
        display: flex;
        align-items: center;
        &:after {
            content: "";
            border-right: 1px solid color(grey, base);;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
        }
    }
}
