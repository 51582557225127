//font faces declarations
@each $font-name, $file-name in $font-face-values{
  @include font-face($font-name){
    @each $src in genFontSrc($base-font-path, $file-name, $font-formats){
      src: $src;
    }
  }
}

@import "config";

@font-face {
  font-family: 'diversey_custom';
  src:  url('#{$icomoon-font-path}/diversey_custom.eot');
  src:  url('#{$icomoon-font-path}/diversey_custom.eot#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/diversey_custom.ttf') format('truetype'),
    url('#{$icomoon-font-path}/diversey_custom.woff') format('woff'),
    url('#{$icomoon-font-path}/diversey_custom.svg#diversey_custom') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FSAlbertArabic-Regular';
  src:  url('#{$icomoon-font-path}/FSAlbertArabic.eot');
  src:  url('#{$icomoon-font-path}/FSAlbertArabic.eot#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/FSAlbertArabic.ttf') format('truetype'),
    url('#{$icomoon-font-path}/FSAlbertArabic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FSAlbertArabic-Bold';
  src:  url('#{$icomoon-font-path}/FSAlbertArabic-Bold.eot');
  src:  url('#{$icomoon-font-path}/FSAlbertArabic-Bold.eot#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/FSAlbertArabic-Bold.ttf') format('truetype'),
    url('#{$icomoon-font-path}/FSAlbertArabic-Bold.woff') format('woff');
}
@font-face {
  font-family: 'FSAlbertArabic-ExtraBold';
  src:  url('#{$icomoon-font-path}/FSAlbertArabic-ExtraBold.eot');
  src:  url('#{$icomoon-font-path}/FSAlbertArabic-ExtraBold.eot#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/FSAlbertArabic-ExtraBold.ttf') format('truetype'),
    url('#{$icomoon-font-path}/FSAlbertArabic-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'FSAlbertArabic-Thin';
  src:  url('#{$icomoon-font-path}/FSAlbertArabic-Thin.eot');
  src:  url('#{$icomoon-font-path}/FSAlbertArabic-Thin.eot#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/FSAlbertArabic-Thin.ttf') format('truetype'),
    url('#{$icomoon-font-path}/FSAlbertArabic-Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FSAlbertArabic-Light';
  src:  url('#{$icomoon-font-path}/FSAlbertArabic-Light.eot');
  src:  url('#{$icomoon-font-path}/FSAlbertArabic-Light.eot#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/FSAlbertArabic-Light.ttf') format('truetype'),
    url('#{$icomoon-font-path}/FSAlbertArabic-Light.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-dv"], [class*=" icon-dv"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'diversey_custom' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-administration {
  &:before {
    content: $icon-administration;
  }
}
.icon-alarm {
  &:before {
    content: $icon-alarm;
  }
}
.icon-assets {
  &:before {
    content: $icon-assets;
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left;
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right;
  }
}
.icon-cash {
  &:before {
    content: $icon-cash;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-dropdown-arrow {
  &:before {
    content: $icon-dropdown-arrow;
  }
}
.icon-energy-consumption {
  &:before {
    content: $icon-energy-consumption;
  }
}
.icon-exclamation {
  &:before {
    content: $icon-exclamation;
  }
}
.icon-export {
  &:before {
    content: $icon-export;
  }
}
.icon-fill-water {
  &:before {
    content: $icon-fill-water;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-live {
  &:before {
    content: $icon-live;
  }
}
.icon-non-compliant {
  &:before {
    content: $icon-non-compliant;
  }
}
.icon-options {
  &:before {
    content: $icon-options;
  }
}
.icon-parameters {
  &:before {
    content: $icon-parameters;
  }
}
.icon-reports {
  &:before {
    content: $icon-reports;
  }
}
.icon-rinse-water {
  &:before {
    content: $icon-rinse-water;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-tick {
  &:before {
    content: $icon-tick;
  }
}
.icon-washcycles {
  &:before {
    content: $icon-washcycles;
  }
}
.icon-washcycles2 {
  &:before {
    content: $icon-washcycles2;
  }
}
