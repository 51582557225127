@import "./config";
@import "./mixins";
@import "./colors";

// Styling for Tables for throughout the application.
.list-container-wrap {
    margin: 15px 8px;
    @include flex(column);
    justify-content: space-between;
}

.ellipses-truncate {
    span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 115px;
        display: block;
        
        &.dropdown-heading {
            max-width: 200px;
        }
    }
}

.header-container {
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 8px;
    .header-title {
        font-family: $font-family-fsalbert-light;
        font-size: 20px;
        color: color_new(blue, diversey_blue);
        margin-bottom: 0;
    }
    .header-actions-buttons {
        display: flex;
    }
    .header-actions {
        padding: 8px;
        align-items: center;
        font-family: $font-family-fsalbert-regular;
        background-color: color_new(blue, diversey_blue);
        color: $color_white;
        font-size: 14px;
        cursor: pointer;
        line-height: 1;
        &:not(:last-child) {
            margin-right: 10px;
        }
        span {
            margin-left: 8px;
        }
    }
}
.fa-plus {
    font-weight: 600;
}
.fa-envelope {
    position: relative;
    top: -1px;
}

.table {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    justify-content: space-between;
    .fa-caret-down {
        margin-left: 5px;
        color: $color_white;
    }
}

.no-data {
    font-size: 15px;
    font-weight: 600;
    color: color(red, light);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.operations {
    justify-content: center;
    i {
        padding: 0px 4px;
        color: darken(color(grey, dark), 20%);
        &:hover,
        &:focus {
            color: color(grey, darker);
        }
    }
    .js_not-active {
        color: lighten($color-red, 20%);
        &:hover,
        &:focus {
            color: lighten($color-red, 5%);
        }
    }
    .fa {
        cursor: pointer;
    }
}

.table__header {
    background-color: color_new(blue, diversey_blue);
    color: $color_white;
    flex-wrap: nowrap;
    min-height: 44px;
    overflow-y: hidden;
    &--row-wrap {
        text-transform: uppercase;
        font-size: 13px;
        font-family: $font-family-fsalbert-bold;
        font-weight: 100;
        line-height: 1.2;
        padding: 7px 10px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex: 1 0 0;
        border-right: 1px solid $color_white;
        @include equal-sized-columns(1, 15);
        @media screen and (max-width: 1100px) {
            font-size: 11px;
        }
        > div {
            max-width: 80%;
            white-space: normal;
            word-wrap: break-word;
        }
        &:last-child {
            border-right: none;
        }
        i {
            cursor: pointer;
            font-size: 17px;
        }
    }
}

.table__body {
    background-color: $color_white;
    min-height: 100px;
    @include flex(column);
    position: relative;
    height: 400px;
    &--row {
        @include flex(row);
        border: 1px solid color(grey, base);
        border-top: none;
        background-color: color(grey, lighter);
        .site-details {
            background-color: $color_white;
        }
        &:nth-child(even) {
            background-color: color(grey, light);
        }
    }
    &--column {
        padding: 6px 10px;
        border-right: 1px solid color(grey, base);
        font-family: $font-family-fsalbert-regular;
        font-size: 14px;
        align-items: center;
        display: flex;
        flex-flow: row nowrap;
        flex: 1 0 0;
        color: color(grey, darker);
        word-break: break-word;
        max-height: 36px;
        @include equal-sized-columns(1, 15);
        &:last-child {
            border-right: none;
        }
    }
}

.content-loader {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.search-container {
    padding: 0;
    position: relative;
    align-items: stretch;
    min-height: 30px;
    background-color: $color_white;
    border-bottom: 1px solid color(grey, light);
    input[type="text"] {
        margin: 0;
        padding: 0 0 0 24px;
        border: none;
        width: 100%;
    }
    .fa {
        position: absolute;
        top: 25%;
        z-index: 1;
    }
    .fa-search {
        left: 7px;
    }
    .fa-close {
        right: 7px;
        cursor: pointer;
    }
    .dropdown-menu {
        width: 140%;
        border: 1px solid color_new(blue, diversey_blue);
        margin-left: -20%;
        left: 0;
        overflow-y: overlay;
    }
    &.dropdown-container.open {
        &:after, &:before {
            bottom: -1px;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }
        &:after {
            border-bottom: 1px solid $color_white;
            border-width: 8px;
            margin-left: -8px;
            z-index: 2;
        }
        &:before {
            border-bottom: 1px solid color_new(blue, diversey_blue);
            border-width: 9px;
            margin-left: -9px;
            z-index: 1;
        }
    }
}

.table-footer {
    font-size: 12px;
    justify-content: space-between;
    margin-top: 10px;
}

.hide-title {
    pointer-events: none;
}

.data__table--wrapper {
    width: 60%;
    .data__table {
        width: 100%;
        text-align: center;
        font-size: 13px;
        font-family: $font-family-fsalbert-regular;
        font-weight: normal;
        border-collapse: collapse;
        border: 1px solid darken(color_new(white, base), 25%);
        thead {
            tr {
                background-color: color_new(white, background_off_white);
                &:last-child {
                    background-color: darken(color_new(white, background_off_white), 4%);
                }
            }
            th {
                padding: 0;
                margin: 0;
            }
        }
        th, td {
            font-weight: normal;
            border-right: 1px solid darken(color_new(white, base), 25%);
        }
        tr {
            border-bottom: 1px solid darken(color_new(white, base), 25%);
        }
        tbody {
            .features-tbl {
                text-align: left;

                > td {
                    padding-left: 10px;
                }
            }

        }
    }
}

.center-text {
    justify-content: center;
}
