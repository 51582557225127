//importing reequired sass files
@import "base";
@import "fonts";
@import "colors";
@each $key,
$val in $flex-direction-values {
    .flex-#{$key} {
        @include flex($val);
    }
}

/**
 ** Overriding default CSS of `ng-select`
 ** it can't be achieve if we write the respective css in individual component.
 ** So don't remove it.
 **/

ng-select {
    height: 18px; // to align the vertical fields border.
    &.disabled {
        opacity: 1;
    }
    .ng-clear-wrapper {
        display: none;
    }
    &.ng-select.ng-select-single {
        .ng-select-container {
            border: none;
            background: transparent;
            height: inherit;
            min-height: inherit;
            &:hover {
                box-shadow: none;
            }
            .ng-value-container {
                padding: 0;
                .ng-input {
                    background: transparent;
                    padding: 0;
                }
                .ng-placeholder {
                    color: #696969;
                }
            }
        }
    }
    &.ng-select-opened {
        .ng-select-container {
            .ng-value-container {
                .ng-input {
                    &:after {
                        transition: 0.5s all cubic-bezier(0.18, 0.89, 0.32, 1.28);
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    &:not(.disabled) {
        .ng-select-container {
            .ng-value-container {
                .ng-input {
                    &:after {
                        content: "";
                        width: 0;
                        height: 0;
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid color_new(blue, diversey_blue);
                        position: absolute;
                        top: 30%;
                        right: 3%;
                        transition: 0.4s all cubic-bezier(0.18, 0.89, 0.32, 1.28);
                    }
                }
            }
        }
    }
}

/**
 ** Overriding the styling.
 ** Desired result was not be able to acheive with adding these style in _forms.scss
 */

.view__section--body-value-wrap {
    .view__section--body-value {
        .flag-holder {
            height: 18px;
        }
    }
}

.ng-option {
    border-bottom: 1px solid color_new(grey, button_disable);
}
