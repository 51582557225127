@import 'config';
@import 'mixins';
@import 'colors';
@import "~@ng-select/ng-select/themes/default.theme.css";
.error {
    padding-left: 10px;
    color: $color-red;
}

.column {
    @include flex(column);
    align-items: stretch;
    .fields-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .system-unique-id {
            display: flex;
            flex-direction: row;
            background-color: color(grey, lighter);
        }
    }
}

.select-box-container {
    @include flex(row);
    justify-content: space-between;
    background: $color_white;
    align-items: center;
    padding: 0.75em;
    margin-bottom: 1em;
    min-height: 49px;
    // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    .multi-select-dropdown {
        width: 55%;
        border-bottom: 1px solid color(grey, base);
    }
}

.selectbox,
select {
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.3rem;
    font-weight: inherit;
    color: color(grey, darker);
    appearance: none;
    padding: 10px 30px 10px 10px;
    border-radius: 0;
    @include arrow-down(color(aqua, dark));
    background-color: $color_white;
}

.text-field-container {
    @include flex(row);
    justify-content: space-between;
    align-items: center;
    background: $color_white;
    min-height: 49px;
    // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    input[type=text],
    input[type=email],
    input[type=number] {
        padding: 0.7em;
        border-bottom: 1px solid color(grey, base);
        background-color: none;
        width: 55%;
        max-height: 30px;
        font-size: 12px;
    }
    .input-wrapper {
        width: 55%;
        position: relative;
        input {
            width: 100%;
        }
    }
}

.input-dropdown {
    position: absolute;
    left: 0;
    top: 100%;
    background: $color_white;
    width: 100%;
    border: 1px solid color(grey, dark);
    border-top: none;
    max-height: 200px;
    overflow: scroll;
    z-index: 10;
    ul {
        padding: 5px 0.7em;
        cursor: pointer;
        &:hover {
            background: color(grey, light);
        }
    }
}

.hideDropdown {
    display: none;
}

label,
.label {
    // Add .label to style where we have custom elements. Eg: Multi select drodown
    font-size: 14px;
    color: color(grey, darker);
}

.form-container {
    display: flex;
    flex-direction: column;
    padding: 1.5em;
    .form-title {
        font-size: 20px;
        color: color_new(blue, diversey_blue);
        margin-bottom: 1em;
    }
    .checkbox-field {
        cursor: pointer;
        label,
        input {
            cursor: pointer;
        }
        input {
            margin-right: 0.7em;
        }
    }
}

.form-section {
    font-size: 13px;
    .section-row {
        margin-bottom: 1.5em;
        width: 100%; // justify-content: space-between;
    }
    .flexible-layout {
        @include flex(row);
        width: 100%;
        .column {
            width: inherit;
            max-width: 32.5%;
            margin-right: 1em;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .column {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 49.5%;
        .fields-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .country-container {
                width: 49.5%;
                .field-row {
                    width: 100%;
                }
            }
            .static-text-area {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: color(grey, light);
            }
        }
    }
    .column.map {
        border: 1px solid color(grey, light);
    }
}

.section-heading {
    padding: 7px;
    font-size: 14px;
    font-weight: normal;
    background-color: lighten(color(grey, base), 7%);
    border: 1px solid lighten(color(grey, base), 3%);
    color: color(grey, darker);
}

.submit-buttons-wrapper {
    justify-content: space-between;
}

.address-fields {
    padding: 3.2em 10px 0 10px;
    border: 1px solid color(grey, base);
    margin-bottom: 10px;
    position: relative;
    .section-heading {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        border: none;
        border-bottom: 1px solid color(grey, base);
    }
}

.action-button-wrapper {
    border-top: 1px solid color(grey, base);
    padding: 10px 0 0;
    margin: 2em 0 8em;
    display: flex;
    justify-content: space-between;
    .btn-save,
    button[value="update"] {
        margin-left: 5px;
    }
    button {
        background: color_new(blue, diversey_blue);
        color: $color_white;
        border: none;
        text-transform: uppercase;
        cursor: pointer;
        padding: 9px 40px 7px;
        font-size: 14px;
        font-family: $font-family-fsalbert-regular;
        line-height: 1;
        &.cancel {
            background: color(red, light);
        }
    }
}

.section-disabled {
    input,
    button,
    label {
        cursor: not-allowed;
        pointer-events: none;
    }
    i.fa-remove {
        opacity: 0.6;
        cursor: not-allowed;
    }
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid color(grey, base);
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background: color(grey, lighter);
    float: right;
}

.two-column-layout {
    @include flex(row);
    justify-content: space-between;
    .column {
        width: 48%;
        margin: 5px;
    }
}

.single-column-layout {
    .column {
        width: 100%;
    }
}

.section {
    margin-bottom: 1em;
}

.control-error-container {
    color: color(red, light);
    > div:first-child {
        margin-top: 5px;
    }
}

select {
    overflow-y: overlay;
    &::-ms-expand {
        display: none;
    }
}

input:focus,
select:focus,
button:focus {
    outline: none;
    border-color: #719ECE;
}

button[type="submit"]:disabled,
.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}

// Exceptions
// Wrapping text in IE10 as the text is long.
.timezone {
    max-width: 70%;
}

/* For Firefox */

input[type='number'] {
    -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// End

// css for custom ng-select dropdown
/* Using Deep nesting to override plugin's CSS
(Plugin is providing deep nesting.)*/
ng-select.ng-select {
    &.custom {
        flex: 0 0 78.5%;
    }
    &.ng-select-single {
        .ng-select-container {
            cursor: text;
            border-radius: 0px;
            min-height: 30px;
            height: 30px;
            background-color: color(grey, lighter);
            .ng-value-container {
                .ng-value-label {
                    z-index: 1;
                    position: relative;
                }
                .ng-placeholder {
                    z-index: 1;
                    font-size: 12px;
                    color: darken(color(grey, dark), 7%);
                }
                .ng-input {
                    font-size: 12px;
                    padding-right: 10px;
                    top: 0;
                    height: 100%;
                    background-color: color(grey, lighter);
                    input {
                        cursor: text;
                        height: 100%;
                        padding: 0;
                    }
                }
            }
        }
    }
    .ng-arrow-wrapper {
        display: none;
    }
}
