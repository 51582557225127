@import "./config";
@import "./colors";

.hierarchy {
    padding: 8px;
    font-family: $font-family-arial;
    font-weight: normal;
    font-size: 1.4rem;
    color: color(grey, darker);
    .header-title {
        font-family: $font-family-fsalbert-light;
        font-size: 2rem;
        color: color_new(blue, diversey_blue);
    }
    &__header {
        justify-content: space-between;
        align-items: center;
        .add-new {
            @include arrow-down($color_white);
            background-color: color_new(blue, diversey_blue);
            color: $color_white;
            min-width: 101px;
            user-select: none;
            padding: 0;
            .dropdown-menu {
                left: 0;
                height: inherit;
                background-color: color_new(blue, diversey_blue);
                overflow: visible;
                > div {
                    display: flex;
                    flex-direction: column;
                }
                span {
                    display: block;
                }
            }
            .add-new-header {
                padding: 10px 30px 10px 10px;
            }
            span {
                padding: 6px 10px;
                font-size: 1.4rem;
            }
        }
    }
    &__search {
        @include flex();
        &__filter {
            box-shadow: 0 1px 4px 0 color(grey, base);
        }
        &__container {
            width: 500px;
            margin-right: 1em;
            background: $color_white;
            box-shadow: 0 1px 4px 0 color_new(grey, base);
            padding: 8px;
            align-items: center;
            justify-content: space-between;
            label {
                font-weight: normal;
            }
            input[type=text] {
                padding: 0.5rem;
                border: 1px solid color(grey, base);
                background-color: color(grey, lighter);
                width: 60%;
            }
            input.no-records {
                border: 1px solid indianred !important;
            }
        }
    }
    &__list {
        @include flex(col);
        background-color: color(grey, light);
        border: 1px solid color_new(grey, dark);
        &__items {
            > .hierarchy__list__item {
                &:last-child {
                    border-bottom: none;
                }
            }
        }
        &__item {
            @include flex(col);
            border-bottom: 1px solid color_new(grey, button_disable);
            // color: color(grey, darker);
            >.hierarchy__list__items {
                margin-left: 24px;
                border-left: 1px solid color(grey, dark);
            }
            &--content {
                @include flex();
                justify-content: space-between;
                padding: 8px;
                background-color: lighten(color_new(grey, button_disable), 25%);
                color: color_new(black, base);

                &.custom {
                    background-color: color_new(white, base);
                    border-bottom: 1px solid color(grey, dark);
                    color: color_new(grey, label);
                }
                &.last {
                    color: color_new(grey, label);
                    background-color: lighten(color_new(grey, button_disable), 18%);

                    &:hover {
                        background-color: lighten(color_new(grey, button_disable), 20%);
                    }
                }
                &.active-parent-node {
                    // background-color: color_new(white, base);
                    background-color: darken(color_new(grey, button_disable), 5%);

                }
                &.expanded {
                    border-bottom: 1px solid color_new(grey, label_float);
                    background-color: darken(color_new(grey, button_disable), 5%);
                }
                &-details {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    .node-state {
                        margin-right: 8px;
                    }
                }
                &-operations {
                    position: relative;
                    font-size: 1.6rem;
                    color: color_new(blue, diversey_blue);
                    align-items: center;
                    .operations-icon {
                        cursor: pointer;
                        display: inline-block;
                        min-width: 1.6rem;
                        margin-left: 8px;
                        &.inactive {
                            color: indianred;
                        }
                    }
                    .add-edit-node-container {
                        position: absolute;
                        top: 100%;
                        right: 100%;
                        @include flex();
                        align-items: stretch;
                        background-color: $color_white;
                        border: 1px solid color_new(grey, dark);
                        font-size: 1.4rem;
                        color: color(grey, darker);
                        .input-container,
                        .action-container {
                            padding: 8px;
                            @include flex(col);
                            justify-content: space-between;
                        }
                        .input-container {
                            border-right: 1px solid color(grey, dark);
                        }
                        .action-container {
                            span {
                                cursor: pointer;
                            }
                        }
                        .header-text {
                            margin-bottom: 8px;
                        }
                        .label-input {
                            margin-top: 8px;
                            @include flex;
                            input {
                                margin-left: 8px;
                                border: 0;
                                border-bottom: 1px solid color(grey, dark);
                            }
                        }
                    }
                }
            }
        }
    }
    .operations-buttons {
        border: 1px solid color_new(blue, diversey_blue);
        appearance: none;
        border-radius: 0;
        text-align: left;
        font-size: 14px;
        font-family: $font-family-arial;
        color: color_new(blue, diversey_blue);
        font-weight: bold;
    }
    .add-branch {
        padding: 4px 20px 4px 10px;
        @extend .operations-buttons;
        background-color: transparent;
        :focus {
            outline: none;
        }
    }
    .add-client {
        padding: 4px 10px;
        cursor: pointer;
        @extend .operations-buttons;
        justify-content: space-between;
    }
    i.disabled {
        color: color_new(grey, label);
    }
}

.accept {
    color: green;
}

.reject {
    color: indianred;
}

// .filter-solutions {
//     select {
//         color: color(grey, darker);
//         // background-image: url("../../../../assets/images/caret_down.png");
//         // background-image: url('data:image/svg+xml;utf8,<svg fill="red" height="1024" width="767.5" xmlns="http://www.w3.org/2000/svg"><path d="M0 384l383.75 383.75L767.5 384H0z"/></svg>');
//     }
// }

.status-indicator {
    margin-left: 16px;
    color: color(aqua, dark);
}

.action-links-container {
    .add-new-link {
        cursor: pointer;
        padding: 8px;
        background-color: color(aqua, dark);
        color: $color_white;
        .link-label {
            margin-left: 8px;
        }
    }
}

span.not-active {
    color: indianred;
}

// Styling for Subsite hierarchy.
.subsite__hierarchy {
    margin-top: 2em;
    &__list {
        width: 100%;
        max-height: 220px;
        overflow-y: scroll;
        padding: 1em;
        border: 1px solid #eee;
        &--item {
            width: 100%;
            margin-top: -1px;
            .subsite__hierarchy__list--item {
                border-left: 15px solid color(grey, base);
            }
        }
        &--child {
            &.expanded {
                >.subsite__hierarchy__list--content {
                    background-color: lighten(color_new(grey, button_disable), 25%);
                    color: color_new(black, base);
                }
                .expanded {
                    >.subsite__hierarchy__list--content {
                        background-color: lighten(color_new(grey, button_disable), 21%);
                    }
                    .expanded {
                        >.subsite__hierarchy__list--content {
                            background-color: lighten(color_new(grey, button_disable), 15%);
                            &:hover {
                                background-color: lighten(color_new(grey, button_disable), 18%);
                            }
                        }
                    }
                }
            }
        }
        &--content {
            padding: 10px;
            cursor: pointer;
            border: 1px solid color(grey, base);
            span {
                font-family: $font-family-arial;
                font-weight: 100;
                font-size: 15px;
                margin-left: 5px;
                text-transform: capitalize;
            }
        }
    }
}

.box-error {
    border-color: color(red, light) !important;
}
