.accordion {
    &__header--text {
        min-height: 44px;
        justify-content: space-between;
        color: $color_white;
        background-color: color_new(blue, diversey_blue);
        border-bottom: 1px solid color_new(blue, diversey_blue);
        align-items: center;
        cursor: default;
        &:hover, .active-header {
            cursor: pointer;
            background-color: color_new(blue, diversey_blue);
        }
    }
    .select-box-container {
        box-shadow: none;
        border: 1px solid color(grey, base);
    }
    &__body {
        border: 1px solid color_new(blue, diversey_blue);
        display : block;
        padding: 20px;
        background-color: $color_white;
        height: auto;
        min-height: 200px;
        overflow-y: scroll;
        transition: max-height 0.2s ease-out;
    }
}
