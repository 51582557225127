@import 'config';
@import 'mixins';

.multi-select-dropdown {
    position: relative;
    width: 100%;
    cursor: pointer;
    padding: 7px 10px;
    display: flex;
    @include arrow-down(color(aqua, dark), $position: 96% 45%);
    background-color: $color_white;
    max-height: 30px;
    .fixed-height {
        height: auto;
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .label {
        @include flex(row);
        align-items: center;
        flex-grow: 1;
        &.timezone-ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            flex-grow: inherit;
            max-width: 100%;
        }
    }
    .multi-select-dropdown-wrapper {
        position: absolute;
        top: 100%;
        left: 0;
        display: none;
        width: 100%;
        background: $color_white;
        font-weight: normal; // overflow: hidden;
        box-shadow: 0px 3px 3px 1px rgba(0, 0, 0, 0.1);
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        z-index: 999;
    }
    .multi-select-label {
        @include flex(row);
        justify-content: space-between;
        padding: 0.5em;
        font-weight: bold;
        background: color(grey, base);
    }
    .multi-select-search {
        border: none;
        padding: 0.2em;
        flex-basis: 100%;
        display: flex;
        justify-content: space-between;
        input {
            border: none;
            background: transparent;
            width: 100%;
            &:hover,
            &:focus {
                outline: none;
            }
        }
        i {
            margin-right: 2px;
        }
    }
    .multi-select-item {
        padding: 8px;
        @include flex;
        justify-content: space-between;
        cursor: pointer;
        &:hover {
            background-color: color(grey, light);
            color: color(grey, light);
        }
        label {
            // flex-basis: 57%;
            cursor: pointer;
        }
        input {
            &[type="radio"] {
                margin-right: 3%;
            }
        }
        &.selected-item {
            background-color: lighten(color(grey, base), 10%);
        }
    }
    &.active {
        .multi-select-dropdown-wrapper {
            display: block;
        }
    }
}

.multi-select-dropdown {
    .label {
        font-size: 12px;
        color: $color_black;
    }
    .multi-select-item {
        label {
            font-size: 12px;
            word-wrap: break-word;
            display: block;
        }
    }
    &.multiple-solutions {
        max-height: inherit;
        padding: 10px 21px 10px 10px;
    }
}

// Custom Dropdown in Filters. For e.g -> /organization/list/Company
.dropdown-menu {
    z-index: 2;
    list-style-type: none;
    display: none;
    position: absolute;
    top: 100%;
    height: 200px;
    overflow-y: scroll;
    background-color: $color_white;
    box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.1);
    border-top: 1px solid color(grey, light);
    width: 100%;
}

.dropdown-toggle {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.dropdown-container {
    @include arrow-down(color(aqua, base));
    label, input {
        cursor: pointer;
    }
    label {
        // to wrap the label text in filterbar;
        max-width: 80%;
        display: block;
    }
    .dropdown-item {
        justify-content: space-between;
        align-items: center;
        padding: 8px 6px;
        border-bottom: 1px solid color(grey, light);
        display: flex;
        flex: 0 0 auto;
    }
}
