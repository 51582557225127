// // Global style for tabs.
@import "./colors";

.tabs {
	width: 100%;
    border-bottom: 2px solid $color_blue;
    margin: 2em 0;
	&__item {
        font-family: $font-family-fsalbert-bold;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        -moz-font-smoothing: grayscale;
        font-size: 12px;
		text-transform: uppercase;
		border-bottom: none;
        position: relative;
        border-left: 1px solid $color_white;
		cursor: pointer;
		top: 2px;
        padding: 10px 30px;
        background: $color_blue;
        color: $color_white;
        &--text {
            line-height: 1;
            display: block;
        }
        &:first-child {
            border-left: none;
        }
        &.active {
            background-color: $color_white;
            color: $color_blue;
            border: 1px solid $color_blue;
            border-bottom: none;
            margin-top: -2px;
            & + .tabs__item  {
                border-left: none;
            }
        }
	}
}
