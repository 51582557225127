 @import "scss/colors";
@import "scss/config";
@import "scss/mixins";

@media all and (min-device-width: 640px) and (max-device-width: 1023px) {
  .solution-sm,
  .solution-lg {
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
  }
  .solution-sm {
    flex-basis: 33.33% !important;
    max-width: 50% !important;
  }
  .solution-lg {
    flex-basis: 50% !important; //max-width: 66.66% !important;
  }
}

/* ===== media queries end ===== */

/*===== document start =====*/

* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

ul, ol{
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a, a:hover, a:active, a:visited {
  color: $color_black;
  text-decoration: none;
}

html,
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

html {
  font-family: $base-font-family;
  font-size: 10px;
}

body {
  font-family: $font-family-fsalbert-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: color_new(white, base);
  margin-bottom: 3em;
}

app-root {
  height: 100%;
}

/*===== document end =====*/

/*===== flex-container start =====*/

.container {
  max-width: 100%;
  @include flex();
}

input{
  outline: 0;
}

//start fix for bootstrap dropdown - remove after removing bootstrap dropdown

.dropdown {
  position: relative;
}

.open {
  .dropdown-menu {
    @include flex(col);
  }
}

/*for typeahead dropdown*/

typeahead-container {
  width: 100%;
  .dropdown-menu{
    width: 100%;
    border: 1px solid $color_black;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    li{
      padding: 4px;
    }
    li:not(:last-of-type){
      border-bottom: 1px solid #000;
    }
  }
}

.disable {
  pointer-events: none;
  opacity: 0.4;
}

.download-message {
  position: absolute;
  right: 0;
  padding-top: 5px;
}

.dropdown-menu {
  .search {
    padding: 4px;
    background: rgba(0,157,218,0.4);
    input {
      border: none;
      margin: 0;
      background: transparent;
      padding: 0;
      font-family: $font-family-arial;
      line-height: 1.5;
      font-size: 14px;
      outline: none;
    }
    .fa-search {
      padding-right: 4px;
      line-height: 1.4;
    }
  }
  .search-container {
    @include flex;
  }
}

.bold {
    font-weight: bold;
}

/*css for google maps and the markers info windows -- start*/

agm-map {
  flex: 1;
  @include flex(col);
}

.agm-map-container-inner {
  @include flex();
  flex: 1;
}

custom-hierarchy-dropdown.options-disabled {
  input {
    cursor: not-allowed !important;
    pointer-events: none;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.container.add-edit-page .feature-list > * {
  width: 30%;
  display: block;
  margin-right: 1em;
  margin-bottom: 1em;
  .solution__body {
    max-height: 200px;
    height: auto;
  }
}

.site-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,0.3);
  z-index: 10;
  .loader {
    position: absolute;
    border: 8px solid #f3f3f3;
    border-top: 8px solid color_new(blue, diversey_blue);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    top: 50%;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
}
