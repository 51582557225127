.overlay {
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

#toast-container > div {
    opacity: 0.9;
}
